import type { IconName } from "@brave/leo/icons/meta";
import type { PaymentProcessor } from "./payments-service";
import type { User } from "./subscription-service";
import { browser } from "$app/environment";

const userStorageKey = "user";

export const sleep = (seconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
};

export const getUser = (): User => {
  const user = sessionStorage.getItem(userStorageKey);
  return JSON.parse(user);
};

export const loadUser = (user: User): void => {
  if (!user) {
    throw new Error("Expected user to be object, but received " + user);
  }
  sessionStorage.setItem(userStorageKey, JSON.stringify(user));
};

export const unloadUser = (): void => {
  sessionStorage.removeItem(userStorageKey);
};

export const getFormattedDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  return Intl.DateTimeFormat("en-us", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  }).format(date);
};

export const getFormattedDiscount = (discount: number) => `${discount * 100}%`;

export const getFormattedPrice = (price: number | string, currency: string = "USD"): string =>
  Intl.NumberFormat("en-us", {
    style: "currency",
    currency,
  }).format(price as number);

export const getRecoveryURL = (domain: string, orderId: string): string => {
  const payload = {
    recovery_expiration: new Date().getTime() + 60_000,
    order_id: orderId,
  };
  const orderPayload = btoa(JSON.stringify(payload));
  return `https://${domain}/?intent=recover&order=${orderPayload}`;
};

// @ts-ignore
export const hasBrowserSkusService = () => !!(navigator.brave && window.chrome.braveSkus);

export const isLinux = (): boolean => !/Android/.test(navigator.userAgent) && /Linux|Ubuntu/.test(navigator.userAgent);

export const propagateSafeParams = (
  from: string | URL,
  to: string | URL,
  { allowAll }: { allowAll: boolean } = { allowAll: false }
): URL => {
  const safeParams = ["ux"];

  let newTo: URL;
  try {
    from = new URL(from);

    /**
     * While this check could be placed above, it's being placed here
     * in order to ensure a URL object is returned.
     */
    if (!to) return from;

    newTo = new URL(to, from?.origin || location.origin);

    for (const [k, v] of from.searchParams) {
      if (!newTo.searchParams.has(k) && (allowAll || safeParams.includes(k))) {
        newTo.searchParams.append(k, v);
      }
    }
  } catch (e) {
    console.error(e);
  }

  return newTo;
};

// @ts-ignore
export const isBrave = (): boolean => navigator.brave?.isBrave;

// intentionally in global scope for use by referral.js
export const is_iOS = () => {
  /**
   * Note that navigator.platform has been deprecated, so the below
   * will have to be refactored to use navigator.userAgentData.platform
   * when there is broader support.
   * See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
   */
  // prettier-ignore
  // @ts-ignore
  return (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) && !window.MSStream;
};

export const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

export const isMobile = () => {
  return isAndroid() || is_iOS();
};

export const isMinChromiumVersion = (minVersion: number): boolean => {
  let { cv } = navigator.userAgent.match(/Chrome\/(?<cv>\d{3})/)?.groups ?? {};
  const chromiumVersion = Number(cv);

  return chromiumVersion >= minVersion;
};

export const paymentProcessorDisplay: Record<PaymentProcessor, { icon: IconName; text: string }> = {
  stripe: {
    icon: "payment-stripe-color",
    text: "Credit card",
  },
  radom: {
    icon: "payment-radom-color",
    text: "Crypto",
  },
  ios: {
    icon: "payment-apple-color",
    text: "iOS",
  },
  android: {
    icon: "payment-googleplay-color",
    text: "Android",
  },
};

export const checkBrowserSupport = (predicateFn?: () => string | undefined) => {
  if (!browser) return;

  let isSupported = true;
  let message: string;

  // If the predicate function returns a truthy value, then the browser isn't supported
  let predicateResult = predicateFn?.();
  if (predicateResult) {
    isSupported = false;
    message = predicateResult;
  }

  return {
    message: message as string,
    isSupported,
  };
};
