<script lang="ts">
  import Button from "@brave/leo/src/components/button/button.svelte";
  import ProgressRing from "@brave/leo/src/components/progress/progressRing.svelte";
  import { onMount } from "svelte";

  export let delay = 3000;
  export let text = "Retry";

  const baseText = text;
  let _text = text;
  let progress = 0;

  onMount(() => {
    let start: number;
    function incrementProgress(timeStamp) {
      if (start === undefined) {
        start = timeStamp;
      }

      const elapsed = timeStamp - start;
      progress = (1 / delay) * elapsed;

      const secsRemaining = Math.ceil(Math.abs(elapsed - delay) / 1000);
      _text = `${baseText} in ${secsRemaining}`;

      if (elapsed < delay) {
        requestAnimationFrame(incrementProgress);
      }
    }

    const animationFrameId = requestAnimationFrame(incrementProgress);

    return () => cancelAnimationFrame(animationFrameId);
  });

  $: isDelayed = progress < 1;
</script>

<Button {...$$restProps} isDisabled={isDelayed}>
  {isDelayed ? _text : baseText}
  <div slot="icon-after" hidden={!isDelayed}>
    {#if isDelayed}
      <ProgressRing mode="determinate" {progress} />
    {/if}
  </div>
</Button>
