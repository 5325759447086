<script lang="ts">
  import Alert from "@brave/leo/src/components/alert/alert.svelte";
  import type { ComponentProps } from "svelte";
  import { slide } from "svelte/transition";
  type ExcludedProps = "type";
  type $$Props = Omit<ComponentProps<Alert>, ExcludedProps> & {
    error: any;
  };

  export let error: any = undefined;
  let errorString = error;
  try {
    errorString = JSON.stringify(error);
  } catch {}

  let showError = false;
  const toggleError = () => {
    showError = !showError;
  };

  let copied = false;
  const copyError = async () => {
    await navigator.clipboard.writeText(errorString);
    copied = true;
    setTimeout(() => (copied = false), 2000);
  };
</script>

<Alert type="error" {...$$restProps}>
  <slot name="title" slot="title" />

  <slot />
  {#if showError}
    <button on:click={toggleError}>Hide details</button> -
    <button on:click={copyError}>{copied ? "Copied!" : "Copy"}</button>
  {:else}
    <button on:click={toggleError}>Show details</button>
  {/if}

  {#if showError}
    <pre class="mt-xl whitespace-pre-wrap [overflow-wrap:anywhere]" transition:slide={{ duration: 300 }}><code
        >{errorString}</code
      ></pre>
  {/if}

  <slot name="content-after" slot="content-after" />

  <slot name="actions" slot="actions" />
</Alert>

<style>
  button {
    text-decoration: underline;
  }
</style>
