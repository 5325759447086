import { base } from "$app/paths";

export const intents = ["checkout", "recover", "connect-receipt", "link-order", "provision"] as const;

export type Intent = (typeof intents)[number];

export const globalIntents: Intent[] = ["checkout", "connect-receipt", "link-order", "recover"];

export const isIntent = <T extends readonly Intent[]>(
  intent: string | undefined | T[number],
  intentSet: T = intents as any
) => intentSet.includes(intent as T[number]);

const intentRedirectsMap: { [P in Intent]?: Function } = {
  "recover": ({ productName }) => `${base}/account/?intent=recover&product=${productName}`,
  "connect-receipt": ({ productName }) => `${base}/receipt-link/?product=${productName}`,
  "link-order": ({ productName }) => `${base}/order-link/?product=${productName}`,
  "checkout": ({ productName }) => `${base}/plans/?intent=checkout&product=${productName}`,
};

export const redirectIntents = Object.keys(intentRedirectsMap) as Intent[];

export const resolveIntentRedirect = (intent: Intent, productName: string) => {
  return intentRedirectsMap[intent]?.({ intent, productName });
};
