<script context="module" lang="ts">
  import { setIconBasePath } from "@brave/leo/src/components/icon/icon.svelte";
  import { writable } from "svelte/store";
  import "@fontsource-variable/inter";
  import "@fontsource/poppins/400.css";
  import "@fontsource/poppins/600.css";
  import "../css/app.css";

  export const isFetching = writable(false);

  setIconBasePath(`${base}/nala-icons`);
</script>

<script lang="ts">
  import { base } from "$app/paths";
  import Loading from "$components/Loading.svelte";
  import AlertCenter from "@brave/leo/src/components/alert/alertCenter.svelte";
  import { fade } from "svelte/transition";
</script>

<AlertCenter />

{#if $isFetching}
  <div
    out:fade={{ duration: 200 }}
    class="fixed left-0 top-0 z-[100] flex h-screen w-screen justify-center bg-container-background py-[30vh] md:bg-page-background"
  >
    <Loading />
  </div>
{/if}

<slot />
