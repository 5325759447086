<script lang="ts">
  import { currencyOptions, discounts, type Currency } from "$lib/products";
  import { getFormattedDiscount, paymentProcessorDisplay } from "$lib/utils";
  import Dropdown from "@brave/leo/src/components/dropdown/dropdown.svelte";
  import Icon from "@brave/leo/src/components/icon/icon.svelte";
  import Label from "@brave/leo/src/components/label/label.svelte";

  export let value: Currency;
  export let availableCurrencies = new Set<Currency>();
  export let isMobile: boolean = false;

  $: options = !availableCurrencies.size
    ? currencyOptions
    : new Map([...availableCurrencies].map((c) => [c, currencyOptions.get(c)]));
  $: selectedCurrencyObj = currencyOptions.get(value);
</script>

<div
  class:[--leo-control-label-direction:row]={!isMobile}
  class:[--leo-control-label-gap:var(--leo-spacing-xl)]={!isMobile}
  class:[--leo-control-label-gap:0]={isMobile}
>
  <Dropdown bind:value>
    <svelte:fragment slot="label">
      {#if !isMobile}
        <div class="self-center">Pay with:</div>
      {/if}
    </svelte:fragment>
    <div slot="left-icon" class="[--leo-icon-height:24px] [--leo-icon-size:36px]">
      <Icon name={selectedCurrencyObj.icon || paymentProcessorDisplay[selectedCurrencyObj.processor].icon} />
    </div>
    <div slot="value">
      <span class:hidden={!selectedCurrencyObj.abbr} class="xs:hidden">{selectedCurrencyObj.abbr}</span>
      <span class:hidden={selectedCurrencyObj.abbr} class="xs:inline">{selectedCurrencyObj.label}</span>
    </div>
    {#each options as [key, { label, abbr, processor, icon }]}
      <leo-option
        value={key}
        class="flex items-center gap-m [--leo-icon-height:24px] [--leo-icon-size:36px]"
        class:p-m={!isMobile}
      >
        <Icon name={icon || paymentProcessorDisplay[processor].icon} />
        <span class:hidden={!abbr} class="xs:hidden">{abbr}</span>
        <span class:hidden={abbr} class="xs:inline">{label}</span>
        {#if key === "BAT"}
          <Label color="green">{getFormattedDiscount(discounts.bat)} OFF</Label>
        {/if}
      </leo-option>
    {/each}
    <div class="border-t border-divider-subtle/40">
      <p class="text-x-small-regular max-w-[200px] p-m text-center text-text-tertiary">
        All crypto payments are processed by our partner Radom. Learn more
      </p>
    </div>
  </Dropdown>
</div>
