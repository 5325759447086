export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [];

export const dictionary = {
		"/(login)": [10,[3]],
		"/(app)/account": [4,[2]],
		"/(app)/debug-info": [5,[2]],
		"/(app)/order-link": [6,[2]],
		"/(app)/plans": [7,[2]],
		"/(app)/receipt-link": [8,[2]],
		"/(app)/support": [~9,[2]],
		"/(login)/verify": [11,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';