<script context="module" lang="ts">
  import { preloadIcon } from "@brave/leo/src/components/icon/icon.svelte";

  preloadIcon("check-normal");
</script>

<script lang="ts">
  import Footer from "$components/Footer.svelte";
  import { APP_NAME } from "$lib/Env";
</script>

<svelte:head>
  <title>{APP_NAME}</title>
</svelte:head>

<slot />

<Footer />
