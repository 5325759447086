<script context="module" lang="ts">
  export type LogInFormEventDetail = { email: string; stopLoader: Function };
  export type LogInFormEvent = { submit: LogInFormEventDetail };
</script>

<script lang="ts">
  import Button from "@brave/leo/src/components/button/button.svelte";
  import Icon from "@brave/leo/src/components/icon/icon.svelte";
  import Input from "@brave/leo/src/components/input/input.svelte";
  import { createEventDispatcher } from "svelte";
  import { quintOut } from "svelte/easing";
  import { slide } from "svelte/transition";

  export let email = "";
  export let buttonText = "Get login link";
  export let hasError = false;
  export let isDisabled = false;
  export let isVertical = false;

  let isLoading = false;

  /**
   * Ensure that errors aren't shown when an email is being typed
   * and that the email is reset if errors are present
   */
  $: {
    hasError = hasError && !email;
    email = hasError ? "" : email;
  }

  const dispatch = createEventDispatcher<LogInFormEvent>();
  const handleSubmit = (e: Event) => {
    e.preventDefault();

    if (email.length >= 1000 || !/\S+@\S+\.\S+/.test(email)) {
      hasError = true;
      email = "";
      return;
    }

    isLoading = true;

    dispatch("submit", {
      email,
      stopLoader: () => (isLoading = false),
    });
  };
</script>

<form class="grid w-full items-start gap-xl" class:isVertical>
  <div style="--leo-control-label-gap: 0">
    <Input
      bind:value={email}
      showErrors={hasError && !email}
      type="email"
      placeholder="Email address"
      mode="outline"
      size="large"
      autofocus
    >
      <div
        slot="errors"
        class="mt-m flex gap-m text-systemfeedback-error-text"
        in:slide={{ duration: 100, easing: quintOut }}
      >
        <Icon name="warning-triangle-outline" />
        Please enter a valid email address.
      </div>
    </Input>
  </div>
  <Button
    onClick={handleSubmit}
    isDisabled={email.length <= 0 || (hasError && !email) || isDisabled}
    kind="filled"
    size="large"
    type="submit"
    {isLoading}
  >
    {buttonText}
  </Button>
</form>

<style>
  @media screen(md) {
    form:not(.isVertical) {
      grid-template-columns: 1fr max-content;
    }
  }
</style>
