<script>
  import { is_iOS, isAndroid, isBrave } from "$lib/utils";
  import Button from "@brave/leo/src/components/button/button.svelte";
  import Icon from "@brave/leo/src/components/icon/icon.svelte";
  import AppStoreBadge from "$images/app-store-badge.svg?url";
  import PlayStoreBadge from "$images/play-store-badge.svg?url";
</script>

<aside class="grid max-sm:grid-rows-[1fr_auto] sm:grid-cols-[1fr_auto] items-center gap-xl p-2xl justify-center">
  <div class="text-large-regular text-text-primary">
    <slot />
  </div>
  {#if !isBrave()}
    {#if is_iOS()}
      <a class="flex justify-center" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/id1052879175">
        <img class="max-w-[225px] w-full" src={AppStoreBadge} alt="App store badge" />
      </a>
    {:else if isAndroid()}
      <a class="flex justify-center" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.brave.browser">
        <img class="max-w-[225px] w-full" src={PlayStoreBadge} alt="Play store badge" />
      </a>
    {:else}
      <Button size="jumbo" target="_blank" href="https://brave.com/download/">
        Download Brave
        <Icon slot="icon-after" name="brave-icon-release-color" />
      </Button>
    {/if}
  {/if}
</aside>

<style>
  aside {
    background: linear-gradient(90deg, #E9EEFF 0%, #FFE7EF 100%);
  }
</style>
