<footer class="site-footer">
    <span class="site-footer__item">2024 © Brave Software, Inc.</span>
    <a class="site-footer__item" href="https://brave.com/terms-of-use/" target="_blank" rel="noopener noreferrer"
      >Terms of Use</a
    >
    <a class="site-footer__item" href="https://brave.com/privacy/browser/" target="_blank" rel="noopener noreferrer"
      >Privacy Policy</a
    >
    <a class="site-footer__item" href="https://hackerone.com/brave" target="_blank" rel="noopener noreferrer"
      >Report a security issue</a
    >
</footer>

<style>
  .site-footer {
    @apply flex flex-row flex-wrap items-center justify-center;
    @apply min-h-[68px] py-2xl px-xl;
  }

  .site-footer__item {
    @apply no-underline;
    @apply text-small-regular text-text-tertiary;
    @apply border-r border-divider-subtle px-m py-0;

    --leo-link-color: theme("colors.text.tertiary");
    --leo-link-visited-color: theme("colors.text.tertiary");
    --leo-link-hover-color: theme("colors.text.primary");

    /* Set text color without overriding Leo anchor styling */
    &:where(:not(a)) {
      color: theme("colors.text.tertiary");
    }

    &:first-child {
      @apply pl-0;
    }
    &:last-child {
      @apply border-0;
    }
  }
</style>
