import { csrfRequest as request } from "./csrf-request";

export type User = {
  subscriber_email: string;
  subscriber_id: string;
  subscriptions: Array<Subscription>;
};

export type Subscription = {
  order_id: string;
  product_id: string;
  subscription_id: string;
};

export const auth = (
  email: string,
  ux?: string,
  intent?: string,
  product_id?: string,
  product_name?: string
): Promise<object> => request("/auth", "POST", { email, ux, intent, product_id, product_name });

export const confirm = (requestId: string, code: string): Promise<User> =>
  request(`/auth/${requestId}`, "PUT", { code });

export const subscribe = (product_id: string): Promise<Subscription> =>
  request("/subscriptions", "POST", { product_id }) as Promise<Subscription>;

export const resubscribe = (product_id: string, subscriptionId: string): Promise<Subscription> =>
  request(`/subscriptions/${subscriptionId}`, "POST", {
    product_id,
  }) as Promise<Subscription>;

export const cancel = (subscriptionId: string): Promise<object> =>
  request(`/subscriptions/${subscriptionId}`, "DELETE");

export const deleteCredentials = (subscriptionId: string): Promise<object> =>
  request(`/subscriptions/${subscriptionId}/credentials`, "DELETE");

export const getSubscriber = (): Promise<User> => request("/subscribers", "GET");

export const linkOrderToSubscription = (orderId: string, receipt: string): Promise<User> =>
  request("/subscriptions/receipt", "POST", {
    order_id: orderId,
    receipt: receipt,
  });

export const createAndLinkOrderToSubscription = (receipt: string): Promise<User> =>
  request("/subscriptions/order-receipt", "POST", {
    receipt: receipt,
  });

export const logout = (): Promise<object> => request("/logout", "PUT");

export const getProducts = (): Promise<object> => request("/products", "GET");
