<script lang="ts">
  import { onNavigate } from "$app/navigation";
  import "@fontsource/poppins/400.css";
  import "@fontsource/poppins/600.css";
  import { fade } from "svelte/transition";

  /**
   * This is so that we can show the same mobile header,
   * even if on a desktop screen. A specific use case for
   * this would be ?ux=mobile on large tablets.
   */
  export let hideNav = false;
  const hasNav = !hideNav && $$slots.navigation;

  let mobileMenuOpen = false;
  export const toggleMobileMenu = () => {
    if (!hasNav) return;

    mobileMenuOpen = !mobileMenuOpen;
  };

  onNavigate(() => {
    mobileMenuOpen = false;
  });
</script>

{#if mobileMenuOpen}
  <div
    transition:fade={{ duration: 150 }}
    id="scrim"
    class="fixed bottom-0 left-0 right-0 top-0 z-40 !h-[100dvh] h-screen bg-dialogs-scrim-background/[.35] backdrop-blur"
  ></div>
{/if}

<div
  class="layout"
  class:has-nav={hasNav}
  class:has-footer={$$slots.footer}
  in:fade={{ duration: 200 }}
>
  {#if $$slots["mobile-header"]}
    <div class="full-bleed sticky top-0 z-30 [grid-area:header]" class:lg:hidden={hasNav}>
      <slot name="mobile-header" {toggleMobileMenu} />
    </div>
  {/if}

  {#if hasNav}
    <div
      class="navigation flex flex-col h-full justify-stretch transition duration-200 ease-in-out [grid-area:nav] max-lg:fixed max-lg:left-0 max-lg:top-0 max-lg:z-50 max-lg:w-[--navigation-width] lg:translate-x-0"
      class:-translate-x-full={!mobileMenuOpen}
    >
      <slot name="navigation" />
    </div>
  {/if}

  <div
    class="content self-stretch md:overflow-y-auto [grid-area:content] md:rounded-xl md:bg-page-background md:p-4xl"
  >
    <slot />
  </div>

  <!-- REVIEW: how does the consumer style things like the border/background of footer? -->
  {#if $$slots.footer}
    <div class="footer [grid-area:footer]">
      <slot name="footer" />
    </div>
  {/if}
</div>

<style>
  :root {
    --leo-navigation-header-padding: theme("spacing.xl");
    --navigation-width: 280px;
  }

  @media screen(md) {
    :root {
      --leo-navigation-header-padding: unset;
    }
  }

  .full-bleed {
    grid-column: 1 / -1;
    place-self: normal;
  }

  .layout {
    --layout-header-row: auto;
    --layout-gutter-top: theme("spacing.2xl");
    --layout-gutter-right: theme("spacing.m");
    --layout-gutter-left: theme("spacing.m");
    --layout-main-columns: 1fr;
    --layout-main-rows: 1fr theme("spacing.m");
    /* prettier-ignore */
    --layout-areas:
      "gutter-l header gutter-r"
      "gutter-l gutter-t gutter-r"
      "gutter-l content gutter-r"
      "gutter-l footer gutter-r";

    min-height: 100vh;
    min-height: 100dvh;
    display: grid;
    align-items: start;
    grid-template-rows: var(--layout-header-row) var(--layout-gutter-top) var(--layout-main-rows);
    grid-template-columns: var(--layout-gutter-left) var(--layout-main-columns) var(--layout-gutter-right);
    grid-template-areas: var(--layout-areas);

    background: theme("colors.container.background");
  }

  .layout.has-footer {
    --layout-main-rows: 1fr auto;
  }

  @media screen(md) {
    .layout {
      --layout-gutter-top: theme("spacing.m");

      height: 100vh;
      height: 100dvh;
    }
  }

  @media screen(lg) {
    .layout.has-nav {
      --layout-gutter-left: 0;
      --layout-main-columns: var(--navigation-width) 1fr;
      /* prettier-ignore */
      --layout-areas:
        "gutter-l nav header gutter-r"
        "gutter-l nav gutter-t gutter-r"
        "gutter-l nav content gutter-r"
        "gutter-l nav footer gutter-r";
    }
  }
</style>
