<script lang="ts">
  import { browser } from "$app/environment";
  import { base } from "$app/paths";
  import LogInForm, { type LogInFormEventDetail } from "$components/LogInForm.svelte";
  import ProductLanding from "$components/ProductLanding.svelte";
  import { isIntent } from "$lib/intents";
  import { getPriceKey, getProductByName, type Currency, type Period } from "$lib/products";
  import { auth } from "$lib/subscription-service";
  import confetti from "canvas-confetti";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import type { PageData } from "./$types";

  export let data: PageData;
  $: ({ intent, productName, uxMode } = data);
  $: product = getProductByName(productName);

  let confettiCanvas: HTMLCanvasElement;
  let emailSubmitted = false;
  let selectedPeriod: Period;
  let selectedCurrency: Currency;
  let congratsConfetti = () => {};

  // In reality, this is currently the productId
  $: priceId =
    selectedPeriod && selectedCurrency && product?.prices?.get(getPriceKey(selectedCurrency, selectedPeriod))?.id;

  if (browser) {
    /**
     * Trigger login email (and confetti)
     */
    congratsConfetti = confetti.create(confettiCanvas, {
      resize: true,
    });

    // set options
    congratsConfetti = congratsConfetti.bind(null, {
      particleCount: 150,
      spread: 180,
      origin: {
        y: 1,
      },
      disableForReducedMotion: true,
    });
  }

  let emailHasError = false;
  const handleSubmit = async (e: CustomEvent<LogInFormEventDetail>) => {
    const { email } = e.detail;

    try {
      if (intent && (priceId || productName)) {
        await auth(email, uxMode, intent, priceId, productName);
      } else {
        await auth(email, uxMode);
      }

      emailSubmitted = true;
      congratsConfetti();
    } catch (e) {
      emailHasError = true;
    }

    e.detail.stopLoader();
  };

  let show = false;
  onMount(() => {
    show = true;
  });
</script>

<div
  class="box overflow-hidden rounded-none p-0 max-md:w-full md:mb-[10vh] md:mt-[20vh] md:rounded-xl [body.product-landing_&]:mt-0 md:[body.product-landing_&]:mt-[10vh]"
>
  <!-- If we prerender this section, we'll get a flash of the wrong form in cases like ?intent=checkout -->
  {#if show}
    <div in:fade={{ duration: 200 }}>
      <header
        class="box relative z-20 flex h-[56px] items-center justify-center rounded-none border-divider-subtle bg-container-background shadow-03 max-md:p-0 md:h-auto md:shadow-none md:[body.product-landing_&]:border-b max-md:[body:not(.product-landing)_&]:mb-[10vh]"
      >
        <img class="h-[28px] md:h-[40px]" src="{base}/images/brave-logo.svg" alt="Brave Premium" />
      </header>
      {#if emailSubmitted}
        <main in:fade={{ duration: 600 }} class="box max-w-[500px] rounded-none pt-0 shadow-none">
          <h1 class="text-heading-h3 text-center text-text-secondary">A login email is on the way</h1>
          <div>
            <p class="pb-xl text-center">Click on the secure login link in the email to access your account.</p>
            <p class="text-center">
              Don't see the email? Check your spam folder or <button
                class="hover:text-60 text-text-interactive underline"
                type="button"
                on:click={() => (emailSubmitted = false)}
              >
                try again
              </button>.
            </p>
          </div>
        </main>
      {:else if isIntent(intent, ["connect-receipt", "link-order"]) && product}
        <main in:fade={{ duration: 600 }} class="box max-w-[500px] rounded-none pt-0 shadow-none">
          <h1 class="text-heading-h3 text-center text-text-secondary">
            Start using your premium subscription on this device
          </h1>
          <p class="text-center">
            You can use your subscription on any combination of 10 Android, iOS, and desktop devices. To get started,
            enter your email below, open the link you receive on this device, and tap the <span
              class="text-default-semibold text-text-primary">Confirm your email</span
            > button.
          </p>
          <LogInForm on:submit={handleSubmit} hasError={emailHasError} isVertical={true} />
        </main>
      {:else if intent === "recover" && product}
        <main in:fade={{ duration: 600 }} class="box max-w-[500px] rounded-none pt-0 shadow-none">
          <h1 class="text-heading-h3 text-center text-text-secondary">
            Start using {product.displayName} on this device
          </h1>
          <p class="text-center">
            Restore your {product.displayName} subscription on this device by logging in to your Brave account.
          </p>
          <LogInForm on:submit={handleSubmit} hasError={emailHasError} isVertical={true} />
        </main>
      {:else if intent === "checkout" && product}
        <ProductLanding {product} bind:selectedPeriod bind:selectedCurrency>
          {@const btnText = product.trialDays ? "Start free trial" : "Go premium"}
          <LogInForm
            on:submit={handleSubmit}
            hasError={emailHasError}
            isDisabled={!product.browserSupport.isSupported}
            buttonText={btnText}
          />
          <p class="text-small-regular text-center text-text-tertiary">
            You're just a step away from {product.displayName}. Just enter your email address and click Go premium to
            get started. You'll be taken to our payment processing service after login.
          </p>
        </ProductLanding>
      {:else}
        <main in:fade={{ duration: 600 }} class="box max-w-[500px] rounded-none pt-0 shadow-none">
          <h1 class="text-heading-h3 text-center text-text-secondary">Log in, or create an account</h1>
          <p class="text-center">
            Enter your email address to get a secure login link. Use this link to either create or access a Premium
            account, and complete your purchase.
          </p>
          <LogInForm on:submit={handleSubmit} hasError={emailHasError} isVertical={true} />
        </main>
      {/if}
    </div>
  {/if}
</div>

<canvas class="contents" bind:this={confettiCanvas} />

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: theme("spacing.3xl");
    color: theme("colors.text.tertiary");
    margin: 0 auto;
  }
</style>
